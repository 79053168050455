<template>
    
  <div class="related-products spad">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-title">
            <h2>Advocates You May Like</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-sm-6">
          <div class="product-item">
            <div class="pi-pic">
              <img
                src="https://acelitigator.com/wp-content/uploads/2023/01/MicrosoftTeams-image-7.png"
                alt=""
              />
              <div class="icon">
                <i class="fa fa-heart"></i>
              </div>
              <router-link to="advocate">
                <ul>
                  <li class="w-icon active">
                    <a href="#"><i class="fa fa-eye"></i></a>
                  </li>
                  <li class="quick-view"><a href="#">+ View Profile</a></li>
                  <li class="w-icon">
                    <a href="#"><i class="fa fa-random"></i></a>
                  </li></ul
              ></router-link>
            </div>
            <div class="pi-text">
              <div class="catagory-name">Advocate</div>
              <a href="#">
                <h5>Brian Owalo</h5>
              </a>
              <div class="product-price">
                Commercial Law
                <span>Litigation,</span>
                <span>Litigation,</span>
                <span>Litigation,</span>
                <span>Criminal Law</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="product-item">
            <div class="pi-pic">
              <img
                src="https://acelitigator.com/wp-content/uploads/2023/01/MicrosoftTeams-image-6.png"
                alt=""
              />
              <div class="icon">
                <i class="fa fa-heart"></i>
              </div>
              <router-link to="advocate">
                <ul>
                  <li class="w-icon active">
                    <a href="#"><i class="fa fa-eye"></i></a>
                  </li>
                  <li class="quick-view"><a href="#">+ View Profile</a></li>
                  <li class="w-icon">
                    <a href="#"><i class="fa fa-random"></i></a>
                  </li></ul
              ></router-link>
            </div>
            <div class="pi-text">
              <div class="catagory-name">Advocate</div>
              <a href="#">
                <h5>Ambrose Waigwa</h5>
              </a>
              <div class="product-price">
                Commercial Law
                <span>Litigation,</span>
                <span>Litigation,</span>
                <span>Litigation,</span>
                <span>Criminal Law</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="product-item">
            <div class="pi-pic">
              <img
                src="https://acelitigator.com/wp-content/uploads/2023/01/nelima.jpg"
                alt=""
              />
              <div class="icon">
                <i class="fa fa-heart"></i>
              </div>
              <router-link to="advocate">
                <ul>
                  <li class="w-icon active">
                    <a href="#"><i class="fa fa-eye"></i></a>
                  </li>
                  <li class="quick-view"><a href="#">+ Quick View</a></li>
                  <li class="w-icon">
                    <a href="#"><i class="fa fa-random"></i></a>
                  </li></ul
              ></router-link>
            </div>
            <div class="pi-text">
              <div class="catagory-name">Advocate</div>
              <a href="#">
                <h5>Nelima Walubengo</h5>
              </a>
              <div class="product-price">
                Commercial Law
                <span>Litigation,</span>
                <span>Litigation,</span>
                <span>Litigation,</span>
                <span>Criminal Law</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="product-item">
            <div class="pi-pic">
              <img
                src="https://acelitigator.com/wp-content/uploads/2023/01/Carolyne-Rono.jpg"
                alt=""
              />
              <div class="icon">
                <i class="fa fa-heart"></i>
              </div>
              <router-link to="advocate">
                <ul>
                  <li class="w-icon active">
                    <a href="#"><i class="fa fa-eye"></i></a>
                  </li>
                  <li class="quick-view"><a href="#">+ View Profile</a></li>
                  <li class="w-icon">
                    <a href="#"><i class="fa fa-random"></i></a>
                  </li>
                </ul>
              </router-link>
            </div>
            <div class="pi-text">
              <div class="catagory-name">Advocate</div>
              <a href="#">
                <h5>Carolyne Rono</h5>
              </a>
              <div class="product-price">
                Commercial Law
                <span>Litigation,</span>
                <span>Litigation,</span>
                <span>Litigation,</span>
                <span>Criminal Law,</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
